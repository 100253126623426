<template>
  <div class="pa-0">
    <v-card
        rounded="lg"
        class="mx-auto"
        tile
        flat
        :elevation="0"
    >
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title v-if="exam_report" class="subtitle-1 text-center text--primary mb-1">
            Total Marks: {{ exam_report.total_marks }}
          </v-list-item-title>
          <v-list-item-title v-if="exam_report" class="subtitle-1 text-center green--text mb-1">
            Obtained Marks: {{ exam_report.obtained_marks }}
          </v-list-item-title>
          <v-list-item-title v-if="exam_report" class="subtitle-1 text-center green--text mb-1">
            Your Position: {{ exam_report.position }} out of {{ exam_report.total_students }}
          </v-list-item-title>
          <v-btn
              v-if="showResult"
              text
              color="primary"
              depressed
              @click="$router.push({name:'AppExamReport',params:{examId: exam_id,token:$route.params.token}})"
          >
            See ranking
          </v-btn>
          <div v-if="!isAnswerAvailable" class="overline mb-4 text-center" style="border-bottom: 2px solid dodgerblue;">
            Answer will be available {{ answerAvailableAt }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ['exam_report', 'isAnswerAvailable', 'answerAvailableAt', 'exam_id', 'showResult'],
  methods: {
    retry() {
      this.$emit('retry');
    },
    practice() {
      {
        this.$emit('practice')
      }
    }
  }
}
</script>