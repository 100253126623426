<template>
  <div>
    <div class="d-flex justify-center align-center" style="min-height: 80vh">
      <v-progress-circular
          v-if="!exam"
          :size="70"
          :width="7"
          color="purple"
          indeterminate
      ></v-progress-circular>
    </div>
    <take-app-exam @submitted="initialize"
                   v-if="exam && exam.mode === 'exam'"
                   :exam="exam"
                   :key="exam.id"
                   :token="token"
    >
    </take-app-exam>
    <app-group-exam @submitted="initialize"
                    v-if="exam && exam.mode === 'group_exam'"
                    :exam="exam"
                    :key="exam.id"
                    :token="token"
    >
    </app-group-exam>
    <app-practice-exam
        v-if="exam && exam.mode === 'practice'"
        :exam="exam"
        :key="exam.id"
    >
    </app-practice-exam>
  </div>
</template>
<script>
import axios from "axios";
import TakeAppExam from "./TakeAppExam";
import AppGroupExam from "./AppGroupExam";
import AppPracticeExam from "./AppPracticeExam";

export default {
  components: {AppPracticeExam, AppGroupExam, TakeAppExam},
  data() {
    return {
      exam: '',
    }
  },
  computed: {
    token() {
      return this.$route.params.token
    }
  },
  methods: {
    initialize() {
      const url = 'exams/' + this.$route.params.examId
      const config = {
        headers: {Authorization: `Bearer ${this.$route.params.token}`}
      };
      axios.get(url, config).then((response) => {
        this.exam = response.data
      })
    }
  },
  mounted() {
    this.initialize()
  }
}
</script>