<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-card
      class="mx-auto overflow-y-auto"
      min-height="400"
      max-height="600"
  >

    <v-toolbar v-if="course && !course.subscribed">
      <v-toolbar-title>
        <div>
          <v-btn
              depressed
              color="success"
              class="mr-2 mb-2 mt-0 pa-0"
              text
          >
            <div v-if="course.discount && !course.subscribed">
              <span class="red--text"><del v-if="course.is_paid">৳{{ course.price }}</del></span> ৳{{ price }}
            </div>
            <div v-else>
              ৳{{ price }}
            </div>
          </v-btn>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
          depressed
          color="success"
          class="mr-2 my-2"
          :to="{name: 'Pay',params:{courseId:$route.params.courseId, course: course}}"
          small
      >
        Buy this course
      </v-btn>
    </v-toolbar>

    <v-list
        color="transparent"
    >
      <v-list-group
          no-action
          v-for="(topic, index) in topics"
          :key="topic.name+topic.id"
          :value="!index"
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-progress-circular
                :rotate="-90"
                :size="20"
                :width="5"
                :value="topic.progress"
                color="primary"
            >
            </v-progress-circular>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ topic.name }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
            v-for="content in topic.contents"
            :key="content.id+content.id"
            link
            dense
            class="ml-0 pl-4"
            @click="loadContent(content);calculateProgress(topic)"
            :disabled="!content.allow ||  !isAvailable(content)"
        >
          <v-list-item-icon v-if="content.video">
            <v-icon v-text="'mdi-video'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="content.video" v-text="content.video.title"></v-list-item-title>

          <v-list-item-icon v-if="content.exam">
            <v-icon v-text="'mdi-newspaper'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="content.exam" v-text="content.exam.title"></v-list-item-title>

          <v-list-item-icon v-if="content.pdf">
            <v-icon v-text="'mdi-file-pdf'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="content.pdf" v-text="content.pdf.title"></v-list-item-title>

          <v-list-item-icon v-if="content.note">
            <v-icon v-text="'mdi-note-outline'"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-if="content.note" v-text="content.note.title"></v-list-item-title>
          <v-list-item-icon v-if="content.completed">
            <v-icon x-small color="green lighten-1">mdi-checkbox-marked-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-subtitle v-if="!isAvailable(content)">
            <v-icon color="blue" small>mdi-clock-outline</v-icon>
            {{ availableAt(content) }}
          </v-list-item-subtitle>

        </v-list-item>
      </v-list-group>
    </v-list>
    <div v-if="loading">
      <v-skeleton-loader
          v-for="i in 20"
          :key="i"
          type="list-item-two-line"
      ></v-skeleton-loader>
    </div>
  </v-card>
</template>
<script>
import axios from 'axios';
import moment from 'moment'
import {mapGetters} from "vuex";

export default {
  data: () => ({
    course: '',
    topics: [],
    loading: true,
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated'
    }),
    price() {
      if (this.course.subscribed) {
        return 'Enrolled'
      }
      let price = this.course.price
      let discount = this.course.discount || 0
      return this.course.is_paid ? price - discount : 'Free'
    },
  },
  methods: {
    calculateProgress(topic) {
      let completed = topic.contents.filter(content => content.completed === true).length
      let total = topic.contents.length
      topic.progress = completed / total * 100
    },
    loadContent(content) {
      this.$emit('loadContent', content)
      if (!(content.type === 'exam' && (content.exam.mode === 'exam' || content.exam.mode === 'group_exam')) && this.authenticated) {
        const url = 'complete'
        let data = {
          content_id: content.id
        }
        axios.post(url, data).then(() => {
          content.completed = true
        }).catch(() => {

        })
      }
    },
    loadContents() {
      this.loadTopics()
    },
    isAvailable(content) {
      return content.available_at ? moment(content.available_at).isBefore(moment()) : true;
    },
    availableAt(content) {
      return moment(content.available_at).format('D MMM [at] hh:mm a')
    },
  },
  watch: {
    topics() {
      if (this.topics[0] && this.topics[0].contents[0]) {
        const content = this.topics[0].contents[0]
        if (content.video) {
          this.loadContent(content)
        } else {
          this.$emit('noContent')
        }
      } else {
        this.$emit('noContent')
      }
      this.loading = false
    }
  }
  ,
  mounted() {
    this.loadContents()
    this.loadCourseOnly()
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0;
}
</style>