<template>
  <v-card flat class="fixed mb-10 pb-5" id="questions">
    <div v-if="!exams.length && !loading" class="d-flex justify-center align-center" style="min-height: 80vh">
      <h2>No corrected mcq for this course.</h2>
    </div>
    <div v-if="loading" class="d-flex justify-center align-center content-loading" style="min-height: 80vh">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
    <v-card flat>
      <v-card-text>
        <div v-for="exam in exams" :key="exam.id+'exam'"
        >
          <h4 style="border-bottom: 2px solid orangered" class="text-center mt-4">
            {{ exam.title }}
          </h4>
          <div v-for="(mcq,index) in exam.mcqs" :key="mcq.id">
            <app-practice-mcq
                :mcq="mcq"
                :index="index"
                v-model="mcq.user_answer"
            ></app-practice-mcq>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script>
import AppPracticeMcq from "./AppPracticeMcq";
import axios from "axios";

export default {
  components: {AppPracticeMcq},
  data() {
    return {
      loading: false,
      mcqs: [],
      exams: [],
    }
  },
  computed: {
    bar() {
      return this.total_tried * 100 / this.mcqs.length
    },
    total_tried() {
      let total = this.mcqs.filter((mcq) => {
        return mcq.user_answer
      })
      return total.length;
    },
    marks() {
      let obtained_marks = 0;
      this.mcqs.forEach((mcq) => {
        if (mcq.user_answer && (mcq.answer === mcq[mcq.user_answer])) {
          obtained_marks++;
        }
        if (mcq.user_answer && (mcq.answer !== mcq[mcq.user_answer])) {
          obtained_marks--;
        }
      });
      return obtained_marks;
    },
  },
  methods: {
    initialize() {
      this.loading = true
      const url = 'courses/' + this.$route.params.courseId + '/reported-mcqs?filter=revised&per_page=100'
      const config = {
        headers: {Authorization: `Bearer ` + this.$route.params.token}
      };
      axios.get(url, config).then((response) => {
        this.exams = response.data.data
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted() {
  },
  created() {
    this.initialize()
  },
  watch: {}
}
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
}

.sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
</style>