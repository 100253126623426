<template>
  <nav>
    <v-app-bar elevate-on-scroll fixed color="white">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title class="cursor-pointer" @click="$router.push('/')">{{ app_name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn x-small text to="/login" class="mx-2 black--text" v-if="!authenticated">
        Login
      </v-btn>
      <v-btn icon depressed class="mx-2 black--text" v-if="authenticated">
        <v-icon>
          mdi-face
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item :to="{ name: 'Admin' }"
                       v-if="user && (user.role === 'admin' || user.role === 'super' || user.role === 'manager')">
            <v-list-item-icon>
              <v-icon>mdi-account-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              Admin
            </v-list-item-title>
          </v-list-item>

          <v-list-item :to="{name: 'About'}">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout" v-if="user ">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data: () => ({
        drawer: false,
        group: null,
        app_name: process.env.VUE_APP_NAME,
        bookFeature: process.env.VUE_APP_BOOK_SHOP === 'true'
      }
  ),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
  },
}
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>