import { render, staticRenderFns } from "./AppMcq.vue?vue&type=template&id=87469d64&scoped=true"
import script from "./AppMcq.vue?vue&type=script&lang=js"
export * from "./AppMcq.vue?vue&type=script&lang=js"
import style0 from "./AppMcq.vue?vue&type=style&index=0&id=87469d64&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "87469d64",
  null
  
)

export default component.exports