<template>
  <v-app>
    <toolbar
        fixed
        v-if="!($route.name === 'AppExam' || $route.name === 'AppExamReport' || $route.name === 'FavoriteMcqs' || $route.name === 'WrongMcqs' || $route.name === 'CorrectMcqs')"
    ></toolbar>
    <!-- Sizes your content based upon application components -->
    <v-main style="margin-top: 64px">

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="pa-0">

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
    <v-footer
        v-if="!($route.name === 'AppExam' || $route.name === 'AppExamReport' || $route.name === 'FavoriteMcqs' || $route.name === 'WrongMcqs' || $route.name === 'CorrectMcqs')"
        padless
    >
      <v-card
          class="flex"
          flat
          tile
      >
        <v-card-text class="py-1 text-center">
          Developed By — <strong><a href="https://nextivesolution.com" target="_blank">Nextive Solution</a> </strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import Toolbar from '@/components/layouts/Toolbar';
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  components: {
    Toolbar
  },
  data() {
    return {
      settings: {}
    }
  },
  mounted() {

  },
  computed: {
    ...mapGetters({
      admin: 'auth/admin'
    })
  },
  methods: {
    redirectIfNotAdmin() {
      if (this.$route) {
        if (this.$route.path.includes('admin') && !localStorage.getItem('admin')) {
          this.$router.push('/')
        }
      }
    }
  },
  created() {
    this.redirectIfNotAdmin()
    const url = 'settings'
    axios.get(url).then(response => {
      const favicon = document.getElementById("favicon");
      favicon.href = response.data.favicon
    }).catch((error) => {
      console.log(error)
    })
  },
  watch: {
    admin() {
      this.redirectIfNotAdmin()
    }
  }
}
</script>
<style>
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: dimgray;
}

body {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
