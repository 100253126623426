<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <div class="row">
      <div class="col-12 col-md-7 col-lg-7 pa-0 pa-lg-2">
        <div v-if="!content" class="d-flex justify-center align-center content-loading">
          <v-card outlined class="d-flex justify-center align-center content-loading">
            <v-progress-circular
                v-if="!message"
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
            <h3 v-else>{{ message }}</h3>
          </v-card>
        </div>
        <router-view v-if="content" :key="$route.fullPath"></router-view>
      </div>
      <div class="col-12 col-md-5 col-lg-5 pa-0 pa-lg-2">
        <course-menu @loadContent="loadContent" @noContent="loadNoContent"></course-menu>
      </div>
    </div>
  </v-container>
</template>
<script>
import CourseMenu from './CourseMenu.vue'
// import ShowVideo from './ShowVideo.vue'
// import TakeExam from './TakeExam.vue'
// import ShowPdf from './ShowPdf.vue'
// import ReadNote from './ReadNote.vue'
import {mapGetters} from 'vuex'
import axios from "axios";
// import PracticeExam from "./PracticeExam";
// import TakeGroupExam from "./TakeGroupExam";

export default {
  components: {
    // TakeGroupExam,
    // PracticeExam,
    CourseMenu,
    // ShowVideo,
    // TakeExam,
    // ShowPdf,
    // ReadNote,
  },
  data: () => ({
    course: '',
    content: '',
    message: '',
  }),
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated'
    })
  },
  methods: {
    loadNoContent() {
      this.message = 'Select an item from the menu to start learnig.'
      scrollTo(0, 0)
    },
    loadContent(e) {
      this.content = ''
      if (e.exam && !this.authenticated) {
        this.$router.push({name: 'Login'})
      }
      this.content = e
      this.message = ''
      this.$router.push({name: 'Content', params: {contentId: e.id}})
      scrollTo(0, 0)
    },
  },
  mounted() {
    scrollTo(0, 0)
  },
  created() {
    if (this.$route.params.contentId) {
      const url = 'contents/' + this.$route.params.contentId
      axios.get(url).then(response => {
        this.content = response.data
      })
    }
  }
}
</script>
<style scoped lang="scss">
.content-loading {
  width: 100%;
  height: 400px;
}

@media (max-width: 600px) {
  .content-loading {
    height: 200px;
  }
}
</style>