import Vue from 'vue'
import axios from 'axios'
import {v4 as uuidv4} from "uuid";

Vue.mixin({
    methods: {
        loadCourse() {
            const url = 'courses/' + this.$route.params.courseId
            axios.get(url).then((response) => {
                this.course = response.data
            })
        },
        loadSettings() {
            const url = 'settings'
            axios.get(url).then((response) =>
                this.settings = response.data)
        },
        uniqueId() {
            return uuidv4.v4()
        },
        loadCourseOnly() {
            const url = 'courses/' + this.$route.params.courseId + '?filter=info'
            axios.get(url).then((response) => {
                this.course = response.data
            })
        },
        loadTopics() {
            const url = 'courses/' + this.$route.params.courseId + '?filter=topics'
            axios.get(url).then((response) => {
                this.topics = response.data
            })
        }
    },
});