import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import axios from 'axios'
import {TiptapVuetifyPlugin} from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import VueFriendlyIframe from 'vue-friendly-iframe';
import VueCountdownTimer from 'vuejs-countdown-timer';
import VueKatex from 'vue-katex';
import 'katex/dist/katex.min.css';
import '@/mixins/common.js';
import DatetimePicker from 'vuetify-datetime-picker';


Vue.use(VueCountdownTimer);
Vue.use(VueFriendlyIframe);
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: 'mdi'
});
Vue.use(VueKatex, {
    globalOptions: {
        delimiters: [
            {left: "$$", right: "$$", display: true},
            {left: "$", right: "$", display: false},
            {left: "\\(", right: "\\)", display: false},
            {left: "\\[", right: "\\]", display: true}
        ]
    }
});
Vue.use(DatetimePicker);

import Router from 'vue-router'

const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
};

require('@/store/subscriber');
axios.defaults.baseURL = process.env.VUE_APP_BACKEND + '/api/'
//axios.defaults.baseURL = 'http://localhost:8000/api/'
Vue.config.productionTip = false;


store.dispatch('auth/attempt', localStorage.getItem('token')).then(() => {
    new Vue({
        vuetify,
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
});
