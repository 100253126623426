import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Course from '@/components/user/course/Course.vue'
import AppExamReport from "../components/user/exam/AppExamReport";
import AppExam from "../components/user/exam/AppExam";
import CorrectedMcqs from "../components/user/exam/CorrectedMcqs";
import FavoriteMcqs from "../components/user/exam/FavoriteMcqs";
import WrongMcqs from "../components/user/exam/WrongMcqs";

Vue.use(VueRouter);

function isAuthenticated(to, from, next) {
    if (store.getters['auth/authenticated']) {
        next();
    } else {
        next('/login');
    }
    next();
}

function isAdmin(to, from, next) {
    next()
}

function isNotAuthenticated(to, from, next) {
    if (!store.getters['auth/authenticated']) {
        next();
    } else {
        next('/');
    }
    next();
}

const routes = [
    {
        path: '/test',
        component: () => import('../components/Test.vue')
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../components/admin/Admin.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/form-fill-up-requests',
        name: 'FormFillUpRequests',
        component: () => import('../components/admin/form_fill_up/FormFillUpRequests.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/send-sms-alert',
        name: 'SendSmsAlert',
        component: () => import('../components/admin/sms_alert/SendSmsAlert.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/form-fill-up-requests/pending',
        name: 'FormFillUpRequestsPending',
        component: () => import('../components/admin/form_fill_up/FormFillUpRequestsPending.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/form-fill-up-requests/accepted',
        name: 'FormFillUpRequestsAccepted',
        component: () => import('../components/admin/form_fill_up/FormFillUpRequestsAccepted.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/form-fill-up-requests/complete',
        name: 'FormFillUpRequestsComplete',
        component: () => import('../components/admin/form_fill_up/FormFillUpRequestsCompete.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/form-fill-up-requests/:formFillUpRequestId',
        name: 'StudentInfo',
        component: () => import('../components/admin/form_fill_up/StudentInfo.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/book',
        name: 'InsertBook',
        component: () => import('../components/admin/book/InsertBook.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/book/:bookId',
        name: 'InsertBookPhoto',
        component: () => import('../components/admin/book/InsertBookPhoto.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/advertisements',
        name: 'ManageAdvertisements',
        component: () => import('../components/admin/utilities/ManageAdvertisements.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/courses',
        name: 'CreateCourse',
        component: () => import('../components/admin/course/CreateCourse.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/courses/subscribers/:courseId',
        name: 'CourseUserList',
        component: () => import('../components/admin/course/CourseUserList.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/categories',
        name: 'Categories',
        component: () => import('../components/admin/Categories.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/tags',
        name: 'Tags',
        component: () => import('../components/admin/Tags.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/payments',
        name: 'Payments',
        component: () => import('../components/admin/payments/Payments.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/notification',
        name: 'Notification',
        component: () => import('../components/admin/notification/SendNotification.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/book-orders',
        name: 'BookOrders',
        component: () => import('../components/admin/book/BookOrders.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/settings',
        name: 'Settings',
        component: () => import('../components/admin/Settings.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/users',
        name: 'Users',
        component: () => import('../components/admin/user/Users.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/mcq-store',
        name: 'McqStore',
        component: () => import('../components/admin/mcq/McqStore.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/mcq-store/:mcqStoreId',
        name: 'McqSubStore',
        component: () => import('../components/admin/mcq/McqSubStore.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/mcq-store/:mcqSubStoreId/mcqs',
        name: 'InsertMcqToMcqStore',
        component: () => import('../components/admin/mcq/InsertMcqToMcqStore.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/mcq-store/:mcqSubStoreId/mcqs/print',
        name: 'PrintMcqs',
        component: () => import('../components/admin/mcq/PrintMcqs.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/notices',
        name: 'Notices',
        component: () => import('../components/admin/notice/Notices.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/routines',
        name: 'ManageRoutines',
        component: () => import('../components/admin/routine/ManageRoutines.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/varsity-categories',
        name: 'VarsityCategories',
        component: () => import('../components/admin/varsity/VarsityCategories.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/varsities/:varsityCategoryId',
        name: 'Varsities',
        component: () => import('../components/admin/varsity/Varsities.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/varsity-infos/:varsityId',
        name: 'VarsityInfos',
        component: () => import('../components/admin/varsity/VarsityInfos.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/varsity/form-fill-up',
        name: 'ManageFormFillUp',
        component: () => import('../components/admin/form_fill_up/ManageFormFillUp.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/add-to-course',
        name: 'AddToCourse',
        component: () => import('../components/admin/course/AddToCourse.vue')
    },
    {
        path: '/admin/subjects',
        name: 'Subjects',
        component: () => import('../components/admin/subject_review/Subjects.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/subjects/:subjectId',
        name: 'SubjectReviews',
        component: () => import('../components/admin/subject_review/SubjectReviews.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/units/:varsityId',
        name: 'Units',
        component: () => import('../components/admin/varsity/Units.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/units/:unitId/details',
        name: 'Unit',
        component: () => import('../components/admin/varsity/Unit.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/units/:unitId/eligibility',
        name: 'Eligibility',
        component: () => import('../components/admin/varsity/Eligibility.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/sms-alert-requests',
        name: 'SmsAlertRequests',
        component: () => import('../components/admin/payments/SmsAlertRequests.vue'),
        beforeEnter: isAdmin
    },
    {
        path: '/admin/popup',
        name: 'Popup',
        component: () => import('../components/admin/popup/Popup.vue'),
        beforeEnter: isAdmin

    },
    {
        path: '/admin/popup/:popupId',
        name: 'PopupResponse',
        component: () => import('../components/admin/popup/PopupResponse.vue'),
        beforeEnter: isAdmin

    },
    {
        path: '/admin/user-guide',
        name: 'ManageUserGuides',
        component: () => import('../components/admin/user_guide/MangeUserGuides.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/courses/:courseId/reported-mcqs',
        name: 'ReportedMcqs',
        component: () => import('../components/admin/mcq/ReportedMcqs.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/mcqs/search',
        name: 'SearchMcq',
        component: () => import('../components/admin/mcq/SearchMcq.vue'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/written-exam-list',
        name: 'WrittenExamList',
        component: () => import('@/components/admin/exam/WrittenExamList'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/written-exam-answer-sheet/:writtenExamId',
        name: 'WrittenExamAnswerSheet',
        component: () => import('@/components/admin/exam/WrittenExamAnswerSheet'),
        beforeEnter: isAdmin,
    },
    {
        path: '/admin/courses/:courseId/',
        component: () => import('../components/admin/course/InsertCourseContents.vue'),
        beforeEnter: isAdmin,
        children: [
            {
                path: '',
                name: 'CourseContentCreate',
                redirect: {
                    name: 'InsertVideo'
                },
                beforeEnter: isAdmin,
            },
            {
                path: 'video',
                name: 'InsertVideo',
                component: () => import('../components/admin/course/InsertVideo.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'video/:videoId',
                name: 'EditVideo',
                component: () => import('../components/admin/course/EditVideo.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'exam',
                name: 'InsertExam',
                component: () => import('../components/admin/course/InsertExam.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'exam/:examId',
                name: 'InsertMcq',
                component: () => import('../components/admin/course/InsertMcq.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'exam/:examId/group',
                name: 'InsertGroupMcq',
                component: () => import('../components/admin/course/InsertGroupMcq.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'note',
                name: 'InsertNote',
                component: () => import('../components/admin/course/InsertNote.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'note/:noteId',
                name: 'EditNote',
                component: () => import('../components/admin/course/EditNote.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'pdf',
                name: 'InsertPdf',
                component: () => import('../components/admin/course/InsertPdf.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'pdf/:pdfId',
                name: 'EditPdf',
                component: () => import('../components/admin/course/EditPdf.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'audio',
                name: 'InsertAudio',
                component: () => import('../components/admin/course/InsertAudio.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'audio/:audioId',
                name: 'EditAudio',
                component: () => import('../components/admin/course/EditAudio.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'written-exam/',
                name: 'InsertWrittenExam',
                component: () => import('../components/admin/course/InsertWrittenExam.vue'),
                beforeEnter: isAdmin,
            },
            {
                path: 'written-exam/:writtenExamId',
                name: 'InsertQuestion',
                component: () => import('../components/admin/course/InsertQuestion.vue'),
                beforeEnter: isAdmin,
            }
        ]
    },
    {
        path: '/success/:courseId',
        name: 'Success',
        component: () => import('../components/user/payments/Success.vue'),
    },
    {
        path: '/',
        name: 'Home',
        component: () => import('../views/Home.vue'),
    },
    {
        path: '/home',
        redirect: {name: 'Home'}
    },
    {
        path: '/about',
        name: 'About',
        beforeEnter: isAuthenticated,
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../components/auth/PasswordLogin.vue'),
        beforeEnter: isNotAuthenticated,
    },
    {
        path: '/recover-password',
        name: 'RecoverPassword',
        component: () => import('../components/auth/RecoverPassword.vue'),
        beforeEnter: isNotAuthenticated,
    },
    {
        path: '/courses/:courseId',
        component: Course,
        name: 'Course',
        children: [
            {
                path: ':contentId',
                component: () => import('../components/user/course/Content.vue'),
                name: 'Content',
            }
        ]
    },
    {
        path: '/books',
        component: () => import('../components/user/book/BookList.vue'),
        name: 'BookList',
    },
    {
        path: '/books/:bookId',
        component: () => import('../components/admin/book/BookDetails.vue'),
        name: 'BookDetails',
    },
    {
        path: '/exams/:examId/ranking',
        component: () => import('../components/user/exam/ExamReport.vue'),
        name: 'ExamReport',
    },
    {
        path: '/exams/:examId/app-ranking/:token',
        // component: () => import('../components/user/exam/AppExamReport'),
        component: AppExamReport,
        name: 'AppExamReport'
    },
    {
        path: '/courses/:courseId/details',
        component: () => import('../components/user/course/CourseDetails.vue'),
        name: 'CourseDetails'
    },
    {
        path: '/courses/:courseId/pay',
        component: () => import('../components/user/payments/Pay.vue'),
        name: 'Pay',
    },
    {
        path: '/exams/:examId/:token',
        name: 'AppExam',
        component: AppExam,
    },
    {
        path: '/wrong-answers/:courseId/:token',
        name: 'WrongMcqs',
        component: WrongMcqs,
    },
    {
        path: '/favorites/:courseId/:token',
        name: 'FavoriteMcqs',
        component: FavoriteMcqs,
    },
    {
        path: '/corrected/:courseId/:token',
        name: 'CorrectMcqs',
        component: CorrectedMcqs,
    },
    {
        path: '/routines',
        name: 'Routines',
        component: () => import('../components/user/routines/Routines.vue')
    },
    {
        path: '/web-exams/:examId',
        component: () => import('../components/user/exam/WebExam.vue'),
        name: 'WebExam'
    },
    {
        path: '/web-exams/:examId/ranking',
        component: () => import('../components/user/exam/WebExamReport.vue'),
        name: 'WebExamReport'
    },
    {
        path: '/home-contents',
        component: () => import('../components/admin/landing/HomeContent'),
        name: 'HomeContent'
    },
    {
        path: '/preview-teachers',
        component: () => import('../components/admin/landing/PreviewTeachers'),
        name: 'PreviewTeachers'
    },
    {
        path: '/hr',
        component: () => import('../components/admin/hr/hr'),
        name: 'Hr'
    },
    {
        path: '/preview-founders',
        component: () => import('../components/admin/landing/PreviewFounders'),
        name: 'PreviewFounders'
    },
    {
        path: '/preview-reviews',
        component: () => import('../components/admin/landing/PreviewReviews'),
        name: 'PreviewReviews'
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router
